import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 遍历 blog 目录下所有 md 文件, 并生成路由
const blogRoutes = []
const blogs = require.context('@/blog', false, /\w+\.(md)$/)
blogs.keys().forEach(fileName => {
  const reg = /\.\/(.+).md/
  const name = fileName.match(reg)[1]
  blogRoutes.push({
    path: name,
    name: name,
    component: resolve => require([`@/blog/${name}.md`], resolve)
  })
})

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/blog',
		name: 'blog',
		component: resolve => require(['@/views/Blog'], resolve),
		children: blogRoutes
	  },
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboard',
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
		props: { default: true, shopInfo: {a:1} }
	},
	{
		path: '/layout',
		name: 'Layout',
		layout: "dashboard",
		component: () => import('../views/Layout.vue'),
	},
	{
		path: '/ImportList',
		name: 'ImportList',
		layout: "dashboard",
		component: () => import('../views/Tables.vue'),
	},
	{
		path: '/billing',
		name: 'Billing',
		layout: "dashboard",
		component: () => import('../views/Billing.vue'),
	},
	{
		path: '/plan',
		name: 'plan',
		layout: "dashboard",
		component: () => import('../views/Subscribe.vue'),
	},
	{
		path: '/rtl',
		name: 'RTL',
		layout: "dashboard-rtl",
		meta: {
			layoutClass: 'dashboard-rtl',
		},
		component: () => import('../views/RTL.vue'),
	},
	{
		path: '/Profile',
		name: 'Profile',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
		},
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
	},
	{
		path: '/subscribe',
		name: 'Subscribe',
		layout: "dashboard",
		component: () => import('../views/Subscribe.vue'),
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		layout: "layout-profile",
		component: () => import('../views/PrivacyPolicy.vue'),
	},
	{
		path: '/index',
		name: 'index',
		layout: "default",
		component: () => import('../views/Index.vue'),
	},
	
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

export default router
