import queryString from 'query-string';

export function query(selector) {
    return document.querySelector(selector);
}

export function getShopInfo(){
    const parsed = queryString.parse(location.search);

    //首先使用链接上的信息
    if (typeof parsed.s != "undefined" && typeof parsed.t != "undefined") {
        return {
            token: parsed.t,
            shop: parsed.s
        }
    }

    //使用存储的信息
    var storage = window.localStorage;
    if (typeof storage.getItem("token") != "undefined" && typeof storage.getItem("shop") != "undefined") {
        return {
            token: storage.getItem("token"),
            shop: storage.getItem("shop")
        }
    }
    return null;
}

export async function checkSubStatus(fn) {
    const shopInfo = getShopInfo();
    if(shopInfo == null){
        alert("please login!");
    }
    //前置校验
    const res = await fetch("//api.transifii.com/spy/app/subscription", {
        method: "GET",
        redirect: "follow",
        headers: {
            "Content-Type": "application/json",
            "X-Spy-Shop": shopInfo.shop,
            "X-Spy-Access-Token": shopInfo.token
        }
    }).then(response => response.json())
        .then(result => {
            if (result.error_type === "NOT_AUTHED") {
                alert("please login!");
                return null;
            }
            fn && fn(result)
            var storage = window.localStorage;
            storage.setItem("token", shopInfo.token);
            storage.setItem("shop", shopInfo.shop);
        }).catch(error => {
            console.log(error, "error")
        });
}

