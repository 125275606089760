<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

 <template>
	<div id="app">
		<component :is="layout">
			<router-view :shopInfo="shopInfo" :subscribeInfo="subscribeInfo"/>
		</component>
	</div>
</template>

<script>
	import { checkSubStatus, getShopInfo } from '@/plugins/checkLogin';

	export default ({
		computed: {
			// Sets components name based on current route's specified layout, defaults to
			// <layout-default></layout-default> component.
			layout() {
				return "layout-" + ( this.$route.meta.layout || "default" ).toLowerCase() ;
			}
		},
		
		data() {
			return {
				shopInfo: {

				},
				userInfo: {

				},
				// {plan:'basic'}
				subscribeInfo: {

				},
				
			}
		},
		// provide: {
		// 	shopInfo: this.shopInfo
		// },

		provide : function() {
			return {
				shopInfo: () => this.shopInfo,
				subscribeInfo: ()=> this.subscribeInfo
			}
		},
		// Provider: {
		// 	provide () {
		// 		return {
		// 		[s]: 'foo'
		// 		}
		// 	}
		// },
		async mounted() {
			this.$data.shopInfo = getShopInfo()
			console.log(this.$data.shopInfo)
			//todo: 进入shopify登录页
			if(!this.$data.shopInfo){
				alert("login")
			}
        	checkSubStatus(res =>{
				this.$data.subscribeInfo = res;
			});
		},

		methods: {


		}

	})


	
</script>

<style lang="scss">
</style>